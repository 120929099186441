import React, { Component } from 'react'

export class Footer extends Component {
    render() {
        return (
            <footer className="footer widget-footer clearfix">
            {this.props.children}
                <div className="first-footer ttm-bgcolor-skincolor ttm-bg ttm-bgimage-yes bg-img1">
                <div className="ttm-row-wrapper-bg-layer ttm-bg-layer" />
                <div className="container-xl">
                    <div className="row align-items-md-center">
                    <div className="col-lg-2 col-md-2 col-sm-12 order-md-2">
                        <div className="footer-logo text-sm-center">
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 order-md-1 res-767-mt-20">
                        <div className="text-left">
                        {/*  featured-icon-box */} 
                        <div className="featured-icon-box left-icon icon-align-top">
                            <div className="featured-icon">{/*  featured-icon */} 
                            <div className="ttm-icon ttm-textcolor-white ttm-icon_element-size-md">
                                <i className="ti ti-location-pin" />{/*  ttm-icon */} 
                            </div>
                            </div>
                            <div className="featured-content">{/*  featured-content */} 
                            <div className="featured-desc">
                                <p>Tower C, 2<sup>nd</sup> Floor, TEK Meadows,</p>
				<p>No.51, Rajiv Gandhi Salai, Sholinganallur,</p>
				<p> Chennai – 600119</p>
                            </div>
                            </div>
                        </div>{/*  featured-icon-box END */}
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 order-md-3 res-767-mt-20">
                        <div className="text-sm-right">
                        <a className="ttm-btn ttm-btn-size-md ttm-btn-style-border ttm-btn-color-white" href="mailto:info@qeagle.com"><i className="far fa-envelope" /> Info@qeagle.com</a>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                <div className="second-footer ttm-textcolor-white bg-img2">
                <div className="container-xl">
                    <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3 widget-area">
                        <div className="widget widget_text  clearfix">
                        <h3 className="widget-title">About Our Company</h3>
                        <div className="textwidget widget-text">
                            One of the finest IT automation engineering company. Providing the highest quality in test automation &amp; RPA development. About more than 20 years of experience and miillions of tests executed.
                        </div>
                        
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3 widget-area">
                        <div className="widget link-widget clearfix">
                        <h3 className="widget-title">Quick Links</h3>
                        <ul id="menu-footer-services">
                            <li><a >Selenium Automation</a></li>
                            <li><a >Cypress Automation</a></li>
                            <li><a >Protractor Automation</a></li>
                            <li><a >Services Automation</a></li>
                            <li><a >End to End Automation</a></li>
                            <li><a >RPA Development</a></li>
                            <li><a >UiPath Bot Automation</a></li>
                            <li><a >Cloud and DevOps Automation</a></li>
                        </ul>
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3 widget-area res-991-mb-40 res-767-mb-0">
                        <div className="widget widget_text clearfix">
                        <h5>Follow Us On</h5>
                            <div className="social-icons circle social-hover">
                            <ul className="list-inline">
                                <li className="social-facebook"><a className="tooltip-top rounded-circle" target="_blank" data-tooltip="Facebook"><i className="fab fa-facebook-f" /></a></li>
                                <li className="social-twitter"><a className="tooltip-top rounded-circle" target="_blank" data-tooltip="Twitter"><i className="fab fa-twitter" /></a></li>
                                <li className="social-flickr"><a className=" tooltip-top rounded-circle" target="_blank" data-tooltip="flickr"><i className="fab fa-flickr" /></a></li>
                                <li className="social-linkedin"><a className=" tooltip-top rounded-circle" href="https://www.linkedin.com/company/qeagle" target="_blank" data-tooltip="Linkedin"><i className="fab fa-linkedin-in" /></a></li>
                            </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3 widget-area res-767-mb-40">
                        <div className="widget flicker_widget clearfix">
                        <h3 className="widget-title">News Letter</h3>
                        <div className="textwidget widget-text">
                            Sign up today for hints, tips and the latest product news
                            <form id="subscribe-form" className="newsletter-form" method="post" action="#" data-mailchimp="true">
                            <div className="mailchimp-inputbox clearfix" id="subscribe-content">
                                <i className="fa fa-envelope" />
                                <input type="email" name="email" placeholder="Email Address.." required />
                                <input type="submit" value="" />
                            </div>
                            <div id="subscribe-msg" />
                            </form>                            
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                <div className="bottom-footer-text ttm-bgcolor-darkgrey ttm-textcolor-white">
                <div className="container-xl">
                    <div className="row copyright align-items-center res-767-text-center">
                    <div className="col-md-6">
                        <div>
                        <span>© 2025&nbsp;<a to="/">Qeagle</a></span>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="text-md-right res-767-mt-10">
                        <div className="d-lg-inline-flex">
                            <ul id="menu-footer-menu" className="footer-nav-menu">
                            <li><a>About Us</a></li>
                            <li><a>Services</a></li>
                            <li><a>Privacy</a></li>
                            </ul>
                            <div className="float-md-right ml-20 res-767-ml-0">
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </footer>
        )
    }
}
