import React, { Component } from 'react';
import Header from '../layout/Header';

export class Leader_grid extends Component {
    constructor(props){
      super();
    }
    render() {
        return (
            <div className="site-main">
                <Header />
                {/* page-title */}
                <div className="ttm-page-title-row">
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-md-6"> 
                            <div className="title-box text-center">
                                <div className="page-title-heading">
                                <h1 className="title">Our Leadership Team</h1>
                                </div>{/* /.page-title-captions */}
                              </div>
                            </div>{/* /.col-md-12 */}  
                        </div>{/* /.row */}  
                    </div>{/* /.container */}                      
                </div>
                <section className="ttm-row grid-section ttm-bgcolor-grey clearfix">
                    <div className="container-xl">
                        {/* row */}
                        <div className="row">
                        <div className="col-lg-4 col-md-6">
                                {/* featured-imagebox-blog */}
                                <div className="featured-imagebox featured-imagebox-blog style2 mb-30">
                                    <div className="featured-thumbnail">{/* featured-thumbnail */}
                                        <img className="img-fluid" src="images/team-member/Karthikeyan.png" alt="Karthikeyan Damodaran" />
                                        <div className="ttm-blog-overlay-iconbox">
                                        </div>
                                        <div className="ttm-box-view-overlay" />
                                    </div>
                                    <div className="featured-content">{/* featured-content */}
                                        <div className="featured-title">{/* featured-title */}
                                            <h5>Karthikeyan Damodaran</h5>
                                            <h5>Investor & Board Member</h5>
                                        </div>
                                    </div>
                                </div>{/* featured-imagebox-blog end */}
                            </div>
                            <div className="col-lg-4 col-md-6">
                                {/* featured-imagebox-blog */}
                                <div className="featured-imagebox featured-imagebox-blog style2 mb-30">
                                    <div className="featured-thumbnail">{/* featured-thumbnail */}
                                        <img className="img-fluid" src="images/team-member/Babu.jpg" alt="" />
                                        <div className="ttm-blog-overlay-iconbox">
                                        </div>
                                        <div className="ttm-box-view-overlay" />
                                    </div>
                                    <div className="featured-content">{/* featured-content */}
                                        <div className="featured-title">{/* featured-title */}
                                            <h5>Babu Narayanan Manickam</h5>
                                            <h5>CEO & Founder</h5>
                                        </div>
                                    </div>
                                </div>{/* featured-imagebox-blog end */}
                            </div>
                            <div className="col-lg-4 col-md-6">
                                {/* featured-imagebox-blog */}
                                <div className="featured-imagebox featured-imagebox-blog style2 mb-30">
                                    <div className="featured-thumbnail">{/* featured-thumbnail */}
                                        <img className="img-fluid" src="images/team-member/MikePeavley.jpg" alt="" />
                                        <div className="ttm-blog-overlay-iconbox">
                                        </div>
                                        <div className="ttm-box-view-overlay" />
                                    </div>
                                    <div className="featured-content">{/* featured-content */}
                                        <div className="featured-title">{/* featured-title */}
                                            <h5>Mike Peavley</h5>
                                            <h5>COO</h5>
                                        </div>
                                    </div>
                                </div>{/* featured-imagebox-blog end */}
                            </div>
                            <div className="col-lg-4 col-md-6">
                                {/* featured-imagebox-blog */}
                                <div className="featured-imagebox featured-imagebox-blog style2 mb-30">
                                    <div className="featured-thumbnail">{/* featured-thumbnail */}
                                        <img className="img-fluid" src="images/team-member/Hari.jpg" alt="" />
                                        <div className="ttm-blog-overlay-iconbox">
                                        </div>
                                        <div className="ttm-box-view-overlay" />
                                    </div>
                                    <div className="featured-content">{/* featured-content */}
                                        <div className="featured-title">{/* featured-title */}
                                            <h5>Hari Radhakrishnan</h5>
                                            <h5>Head - Projects</h5>
                                        </div>
                                    </div>
                                </div>{/* featured-imagebox-blog end */}
                            </div>
                            <div className="col-lg-4 col-md-6">
                                {/* featured-imagebox-blog */}
                                <div className="featured-imagebox featured-imagebox-blog style2 mb-30">
                                    <div className="featured-thumbnail">{/* featured-thumbnail */}
                                        <img className="img-fluid" src="images/team-member/Saravanan.jpg" alt="" />
                                        <div className="ttm-blog-overlay-iconbox">
                                        </div>
                                        <div className="ttm-box-view-overlay" />
                                    </div>
                                    <div className="featured-content">{/* featured-content */}
                                        <div className="featured-title">{/* featured-title */}
                                            <h5>Saravanan Loganathan</h5>
                                            <h5>Head - People</h5>
                                        </div>
                                    </div>
                                </div>{/* featured-imagebox-blog end */}
                            </div>
                            <div className="col-lg-4 col-md-6">
                                {/* featured-imagebox-blog */}
                                <div className="featured-imagebox featured-imagebox-blog style2 mb-30">
                                    <div className="featured-thumbnail">{/* featured-thumbnail */}
                                        <img className="img-fluid" src="images/team-member/Sudharasan.jpg" alt="" />
                                        <div className="ttm-blog-overlay-iconbox">
                                        </div>
                                        <div className="ttm-box-view-overlay" />
                                    </div>
                                    <div className="featured-content">{/* featured-content */}
                                        <div className="featured-title">{/* featured-title */}
                                            <h5>Sudharsan Ramanujam</h5>
                                            <h5>Head - Process Automation</h5>
                                        </div>
                                    </div>
                                </div>{/* featured-imagebox-blog end */}
                            </div>
                            <div className="col-lg-4 col-md-6">
                                {/* featured-imagebox-blog */}
                                <div className="featured-imagebox featured-imagebox-blog style2 mb-30">
                                    <div className="featured-thumbnail">{/* featured-thumbnail */}
                                        <img className="img-fluid" src="images/team-member/Mohan.jpg" alt="" />
                                        <div className="ttm-blog-overlay-iconbox">
                                        </div>
                                        <div className="ttm-box-view-overlay" />
                                    </div>
                                    <div className="featured-content">{/* featured-content */}
                                        <div className="featured-title">{/* featured-title */}
                                            <h5>Mohan Balakrishnan</h5>
                                            <h5>Head - Cloud Automation</h5>

                                        </div>
                                    </div>
                                </div>{/* featured-imagebox-blog end */}
                            </div>
                          
                        </div>{/* row end */}
                        </div>
                    </section>
            </div>            
        )
    }
}

export default Leader_grid;